import React, { useEffect, useState } from 'react';
import colors from '../../constants/colors';
import twitterIcon from '../../assets/pictures/contact-twitter.png';
import ghIcon from '../../assets/pictures/contact-gh.png';
import inIcon from '../../assets/pictures/contact-in.png';
import ResumeDownload from './ResumeDownload';

interface ContactProps {}

const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};

interface SocialBoxProps {
    icon: string;
    link: string;
}

const SocialBox: React.FC<SocialBoxProps> = ({ link, icon }) => (
    <a rel="noreferrer" target="_blank" href={link}>
        <div className="big-button-container" style={{ width: 36, height: 36 }}>
            <img src={icon} alt="" style={{ width: '100%', height: '100%' }} />
        </div>
    </a>
);

const Contact: React.FC<ContactProps> = () => {
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formMessage, setFormMessage] = useState('');
    const [formMessageColor, setFormMessageColor] = useState('');

    useEffect(() => {
        setIsFormValid(validateEmail(email) && name.trim() !== '' && message.trim() !== '');
    }, [email, name, message]);

    const submitForm = async () => {
        if (!isFormValid) {
            setFormMessage('Form unable to validate, please try again.');
            setFormMessageColor('red');
            return;
        }
        setIsLoading(true);
        try {
            const res = await fetch('https://repotst-1.onrender.com/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ company, email, name, message }),
            });
            const data = await res.json();
            console.log(res);
            if (res.ok) {
                setFormMessage(`Message successfully sent. Thank you ${name}!`);
                setCompany('');
                setEmail('');
                setName('');
                setMessage('');
                setFormMessageColor(colors.blue);
            } else {
                setFormMessage(data.error || 'An error occurred while sending the message.');
                setFormMessageColor(colors.red);
            }
        } catch (error) {
            setFormMessage('There was an error sending your message. Please try again.');
            setFormMessageColor(colors.red);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setFormMessage('');
            setFormMessageColor('');
        }, 4000);
        return () => clearTimeout(timeout);
    }, [formMessage]);

    return (
        <div className="site-page-content">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>Contact</h1>
                <div style={{ display: 'flex' }}>
                    <SocialBox icon={ghIcon} link={'https://github.com/ahmadnasrallahh'} />
                    <SocialBox icon={inIcon} link={'https://www.linkedin.com/in/ahmad--nasrallah/'} />
                    <SocialBox icon={twitterIcon} link={'https://twitter.com/naasrallahh'} />
                </div>
            </div>
            <div className="text-block">
                <p>
                    I am currently employed, however if you have any opportunities, feel free to reach out - I would love to chat! You can reach me via my personal email, or fill out the form below!
                </p>
                <br />
                <p>
                    <b>Email: </b>
                    <a href="mailto:naasrallahh@gmail.com">naasrallahh@gmail.com</a>
                </p>
                <div style={{ flexDirection: 'column', marginTop: 32 }}>
                    <label>
                        <p>
                            {name.trim() === '' && <span style={{ paddingRight: 4, color: 'red' }}>*</span>}
                            <b>Your name:</b>
                        </p>
                    </label>
                    <input
                        style={{ marginTop: 4, marginBottom: 16 }}
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <label>
                        <p>
                            {!validateEmail(email) && <span style={{ paddingRight: 4, color: 'red' }}>*</span>}
                            <b>Email:</b>
                        </p>
                    </label>
                    <input
                        style={{ marginTop: 4, marginBottom: 16 }}
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>
                        <p>
                            <b>Company (optional):</b>
                        </p>
                    </label>
                    <input
                        style={{ marginTop: 4, marginBottom: 16 }}
                        type="text"
                        name="company"
                        placeholder="Company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                    />
                    <label>
                        <p>
                            {message.trim() === '' && <span style={{ paddingRight: 4, color: 'red' }}>*</span>}
                            <b>Message:</b>
                        </p>
                    </label>
                    <textarea
                        name="message"
                        placeholder="Message"
                        style={{ marginTop: 4, marginBottom: 16 }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <button
                            className="site-button"
                            style={{ minWidth: 184, height: 32 }}
                            type="submit"
                            disabled={!isFormValid || isLoading}
                            onClick={submitForm}
                        >
                            {isLoading ? <p className="loading">Sending</p> : 'Send Message'}
                        </button>
                        <div style={{ textAlign: 'right', flexDirection: 'column', alignItems: 'flex-end', paddingLeft: 24 }}>
                            <p style={{ color: formMessageColor }}>
                                <b>
                                    <sub>{formMessage || 'All messages get forwarded straight to my personal email'}</sub>
                                </b>
                            </p>
                            <p><sub>{!isFormValid && <span><b style={{ color: 'red' }}>*</b> = required</span>}</sub></p>
                        </div>
                    </div>
                </div>
            </div>
            <ResumeDownload altText="Need a copy of my Resume?" />
        </div>
    );
};

export default Contact;
