import React from 'react';
import ResumeDownload from './ResumeDownload';

export interface ExperienceProps {}

const Experience: React.FC<ExperienceProps> = (props) => {
    return (
        <div className="site-page-content">
            <ResumeDownload />
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>Norwegian Refugee Council</h1>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={'https://nrc.no/'}
                        >
                            <h4>www.nrc.no</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Regional ICT Officer</h3>
                        <b>
                            <p>Nov 2021 - Present</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                Spearheaded implementing and enforcing SOPs, policies, and guidelines, ensuring seamless operations and regulatory compliance.
                </p>
                <br />
                <ul>
                    <li>
                        <p>
                        Participate in the development, implementation and support of in-house web based NRC digital systems
                        </p>
                    </li>
                    <li>
                        <p>
                        Deliver comprehensive induction sessions on technology and systems, streamlining onboarding processes and boosting productivity.
                        </p>
                    </li>
                </ul>
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>Recovery</h1>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://recoveryjo.com/'}
                        >
                            <h4>www.recoveryjo.com</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Odoo ERP Consultant</h3>
                        <b>
                            <p>Dec 2021 – Nov 2022</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                Extensive experience as a Python Developer Consultant specializing in Odoo ERP implementation and customization.
                </p>
                <br />
                <ul>
                    <li>
                        <p>
                        Proven track record of successfully delivering end-to-end solutions using Python and Odoo framework, enhancing operational efficiency and streamlining business processes.
                        </p>
                    </li>
                    <li>
                        <p>
                        Strong expertise in analyzing client requirements, designing custom modules, and providing technical guidance, resulting in optimized ERP solutions and improved business outcomes.
                        </p>
                    </li>
                </ul>
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>cisco</h1>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://www.cisco.com/'}
                        >
                            <h4>www.cisco.com</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Cisco TAC Engineer</h3>
                        <b>
                            <p>Jan 2020 – Nov 2021</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                Provided world-class technical support and troubleshooting expertise, ensuring prompt resolution of complex issues for diverse clients.
                </p>
                <br />
                <ul>
                    <li>
                        <p>
                        Collaborated seamlessly with cross-functional teams to design and deploy cutting-edge network solutions tailored to clients' unique requirements.
                        </p>
                    </li>
                    <li>
                        <p>
                        Conducted engaging and informative training sessions, empowering clients to maximize the benefits of Cisco technologies and drive success.
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    header: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    skillRow: {
        flex: 1,
        justifyContent: 'space-between',
    },
    skillName: {
        minWidth: 56,
    },
    skill: {
        flex: 1,
        padding: 8,
        alignItems: 'center',
    },
    progressBar: {
        flex: 1,
        background: 'red',
        marginLeft: 8,
        height: 8,
    },
    hoverLogo: {
        height: 32,
        marginBottom: 16,
    },
    headerContainer: {
        alignItems: 'flex-end',
        width: '100%',
        justifyContent: 'center',
    },
    hoverText: {
        marginBottom: 8,
    },
    indent: {
        marginLeft: 24,
    },
    headerRow: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export default Experience;
